import { useMediaQuery, useTheme } from '@mui/material';
import { IClaim } from 'types/Claim';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { TRANSLATION_KEYS } from 'utils/constants';
import { useTranslate } from '@tolgee/react';
import { CompositeTypography } from 'styles';

const ClaimDetailsReasonCode = ({ claim }: { claim: IClaim }) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
				width: !!desktop ? '1344px' : '100%',
				maxWidth: '1344px',
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<Typography variant="body">
					<CompositeTypography
						label={tClientLabels('claim.labels.reasonCode')}
						value={claim?.reasonCode as string}
					/>
				</Typography>
				<Typography variant="body">
					<CompositeTypography
						label={tClientLabels('claim.labels.internalNote')}
						value={claim?.internalNote ? (claim.internalNote as string) : '-'}
					/>
				</Typography>
			</Stack>
		</Paper>
	);
};

export default ClaimDetailsReasonCode;
