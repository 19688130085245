import {
	AuthenticationResult,
	PublicClientApplication,
} from '@azure/msal-browser';

import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import axios from 'axios';
import { msalConfig } from 'b2c/b2cConf';
import packageJson from '../../package.json';
import { cleanupStorage } from './localStorage';

let reduxStore: ToolkitStore;
const tokenInstance = new PublicClientApplication(msalConfig);

export const injectReduxStore = (_store: ToolkitStore) => {
	reduxStore = _store;
};

const authFetch = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export const acquireAccessToken = async () => {
	const activeAccount = tokenInstance.getActiveAccount();
	const accounts = tokenInstance.getAllAccounts();

	const account = activeAccount || accounts[0];
	if (!account) {
		throw new Error('User not logged in');
	}

	const request = {
		scopes: [],
		account: account,
	};

	await tokenInstance.initialize();
	let authResult: AuthenticationResult;
	try {
		authResult = await tokenInstance.acquireTokenSilent(request);
	} catch (err: any) {
		if (err?.errorCode === 'interaction_required') {
			cleanupStorage();
			tokenInstance.acquireTokenRedirect(request);
		}
		throw err;
	}

	const token = `Bearer ${authResult.idToken}`;

	authFetch.defaults.headers.common['Authorization'] = token;

	return token;
};

authFetch.interceptors.request.use(
	async config => {
		const { version } = packageJson;
		const typeRegex = /test|qa|preview/;
		const envTypeIdx = version.search(typeRegex);

		if (!config.headers!.Authorization || (config as any).retry) {
			config.headers!.Authorization = await acquireAccessToken();
		}

		// If token is expired first call throw 401 and second one loose content-type 'multipart/form-data'
		// this condition manages this case
		// SORRY the mess :'(
		if (config.data instanceof FormData) {
			config.headers!['Content-Type'] =
				config.headers!['Content-Type'] || 'multipart/form-data';
		} else {
			config.headers!['Content-Type'] =
				config.headers!['Content-Type'] || 'application/json';
		}

		config.headers!['Mercurio-Client-Type'] =
			config.headers!['Mercurio-Client-Type'] || 'admin-portal';

		config.headers!['Accept-Language'] = 'en-gb'; //TODO: make it dynamic when fixing language package

		if (envTypeIdx > -1) {
			const envType = version.slice(envTypeIdx, version.length);
			config.headers!['Mercurio-Client-Env'] = envType;
		}

		return config;
	},
	error => {
		console.error('error', error);
		return Promise.reject(error);
	},
);

authFetch.interceptors.response.use(
	response => response,
	async error => {
		const retry = error.config?.retry;

		if (!retry) {
			return authFetch.request({ ...error.config, retry: true });
		}

		return Promise.reject(error);
	},
);

export const checkForUnauthorizedResponse = (
	error: { response: string },
	thunkAPI: any,
) => {
	console.error('error', error);
	return thunkAPI.rejectWithValue(error.response);
};

export default authFetch;
