import { AppDispatch, RootState } from 'store/store';
import {
	BoxOverflow,
	Button,
	Stack,
	Table,
	TableBody,
	TableCell as TableCellUnstyled,
	TableHead,
	TableRow,
	Typography,
} from 'styles';
import ClaimsOperationIcon, {
	Sign,
} from 'components/commons/ClaimIcons/ClaimsOperationIcon.component';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { IClaim, IClaimComponents } from 'types/Claim';
import { calculateEstimates, setCalculated } from 'feature/claims/claimSlice';
import { formatInputNumberValue, formatPrice } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';

import InputNumber from 'styles/input/InputNumber';
import { breakpoints } from 'styles/theme/Breakpoints';
import palette from 'styles/theme/Palette';
import styled from 'styled-components';
import { useTranslate } from '@tolgee/react';
import { TRANSLATION_KEYS } from 'utils/constants';

type prescriptionProps = {
	formData: IClaim;
	setFormData: (changeFunction: (prevValue: IClaim) => IClaim) => void;
	status: 'beforeCalculation' | 'afterCalculation' | 'onEdit';
	setStatus: Dispatch<
		SetStateAction<'beforeCalculation' | 'afterCalculation' | 'onEdit'>
	>;
	showGross?: boolean;
};

const PriceEstimate: FC<prescriptionProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<AppDispatch>();

	const { formData, status, setStatus, showGross = true } = props;
	const amount = formData.amount;

	const [tempAmounts, setTempAmounts] = useState<IClaim['amount']>(amount);
	const [isError, setIsError] = useState<boolean>(false);
	const calculated = useSelector((state: RootState) => state.claim.calculated);

	useEffect(() => {
		if (calculated) {
			setStatus('afterCalculation');
			dispatch(setCalculated(false));
		}
	}, [calculated, dispatch, amount, setStatus]);

	useEffect(() => {
		if (['onEdit', 'beforeCalculation'].includes(status)) {
			resetTempAmounts(amount);
		} else {
			setTempAmounts(amount);
		}
	}, [amount, status]);

	const resetTempAmounts = (amounts: IClaim['amount']) => {
		setTempAmounts(() => ({
			...amounts,
			components: amounts.components.map(el => ({
				serviceId: el.serviceId,
				retailAmount: el.retailAmount,
				grossRetail: el.grossRetail,
			})),
			total: {
				serviceId: null,
				retailAmount: '',
				grossRetail: 0,
			},
			accumulator: undefined,
		}));
	};

	const handleChangeAmounts = (
		formattedValue: string,
		row: IClaimComponents,
		index: number,
		key: string,
	) => {
		const newComponents = [...tempAmounts.components];
		newComponents[index] = {
			...row,
			[key]: formattedValue,
		};

		const newTempAmounts = {
			...tempAmounts,
			components: newComponents,
		};

		if (isError) {
			const { components } = newTempAmounts;
			const error = components.some(
				el =>
					parseFloat(el.grossRetail?.toString() || '') <
					parseFloat(el.retailAmount?.toString() || ''),
			);

			if (!error) {
				setIsError(false);
			}
		}

		setTempAmounts(newTempAmounts);
	};

	const addCurrency = (value: number | string | undefined) => {
		return ['afterCalculation'].includes(status)
			? formatPrice(value ?? '').replace('€', '€ ')
			: '';
	};

	const handleCalculate = () => {
		const { components } = tempAmounts;
		const error =
			showGross &&
			components.some(
				el =>
					parseFloat(el.grossRetail?.toString() || '') <
					parseFloat(el.retailAmount?.toString() || ''),
			);

		if (error) {
			setIsError(true);
		} else {
			const newFormData = Object.keys(formData)
				.filter(objKey => objKey !== 'claimId')
				.reduce((newObj: any, key: any) => {
					newObj[key] = formData[key as keyof IClaim];
					return newObj;
				}, {});

			dispatch(calculateEstimates({ ...newFormData, amount: tempAmounts! }));
		}
	};

	const totals = tempAmounts.total;

	return (
		<Stack gap="30px">
			<BoxOverflow variant="x" sx={{ width: { sm: 830, lg: 1500 } }}>
				<Stack gap="20px" sx={{ width: 1500 }}>
					<Stack justifyContent="space-between" direction="row">
						<Typography variant="title6">
							{tClientLabels('newClaim.selectedPage.sectionTitile.priceEstimate')}
						</Typography>
						{['afterCalculation'].includes(status) ? (
							<Button
								variant="linkDark"
								onClick={() => {
									setStatus('onEdit');
								}}
								style={{ display: amount.total.total ? 'flex' : 'none' }}
							>
								{tClientLabels('newClaim.priceEstimate.edit')}
							</Button>
						) : null}
					</Stack>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<Typography variant="bodyLink" sx={{ pl: '10px' }}>
										{tClientLabels('newClaim.priceEstimate.visionProduct')}
									</Typography>
								</TableCell>
								{showGross ? (
									<>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{tClientLabels('newClaim.priceEstimate.grossRetail')}
											</Typography>
										</TableCell>
										<TableCell align="center" $smallIcon>
											<ClaimsOperationIcon sign={Sign.MINUS} />
										</TableCell>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{tClientLabels('newClaim.priceEstimate.inStorePromotion')}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<ClaimsOperationIcon sign={Sign.EQUAL} />
										</TableCell>
									</>
								) : null}
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.retailAmount')}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon>
									<ClaimsOperationIcon sign={Sign.MINUS} />
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.contractualAdjustments')}
									</Typography>
								</TableCell>
								<TableCell align="center">
									<ClaimsOperationIcon sign={Sign.EQUAL} />
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.memberPay')}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon>
									<ClaimsOperationIcon sign={Sign.PLUS} />
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.planPay')}
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tempAmounts.components.map((row, index) => (
								<TableRow
									key={row.serviceId}
									sx={{
										height: { sm: 56, lg: 70 },
										backgroundColor: palette.white.main,
										outline: `2px solid ${palette.grey6.main}`,
									}}
								>
									<TableCell sx={{ width: { sm: 136, lg: 230 } }}>
										<Typography variant="body" sx={{ pl: '10px' }}>
											{tClientLabels(serviceIdToLable(row.serviceId!))}
										</Typography>
									</TableCell>
									{showGross ? (
										<>
											<TableCell align="center">
												{['beforeCalculation', 'onEdit'].includes(status) ? (
													<OutlinedInput
														error={
															isError &&
															parseFloat(row.grossRetail?.toString() || '') <
																parseFloat(row.retailAmount?.toString() || '')
														}
														isCurrency={true}
														value={formatInputNumberValue(row.grossRetail || '')}
														handleInputNumberChange={formattedValue =>
															handleChangeAmounts(formattedValue, row, index, 'grossRetail')
														}
													/>
												) : (
													<Typography variant="value1">
														{addCurrency(row.grossRetail)}
													</Typography>
												)}
											</TableCell>
											<TableCell align="center" $smallIcon></TableCell>
											<TableCell align="center">
												<Typography variant="value1">
													{addCurrency(row.inStorePromotion)}
												</Typography>
											</TableCell>
											<TableCell align="center" $smallIcon></TableCell>
										</>
									) : null}
									<TableCell align="center">
										{['beforeCalculation', 'onEdit'].includes(status) ? (
											<OutlinedInput
												error={
													isError &&
													parseFloat(row.grossRetail?.toString() || '') <
														parseFloat(row.retailAmount?.toString() || '')
												}
												isCurrency={true}
												value={formatInputNumberValue(row.retailAmount || '')}
												handleInputNumberChange={formattedValue =>
													handleChangeAmounts(formattedValue, row, index, 'retailAmount')
												}
											/>
										) : (
											<Typography variant="value1">
												{addCurrency(row.retailAmount)}
											</Typography>
										)}
									</TableCell>
									<TableCell align="center" $smallIcon></TableCell>
									<TableCell align="center">
										<Typography variant="value1">
											{addCurrency(row.contractualAdjustmentAmount)}
										</Typography>
									</TableCell>
									<TableCell align="center" $smallIcon>
										<Typography variant="value1" style={{ color: palette.grey2.main }}>
											{addCurrency(row.discountedAmount)}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="value1">
											{addCurrency(row.memberPayAmount)}
										</Typography>
									</TableCell>
									<TableCell align="center" $smallIcon></TableCell>
									<TableCell align="center">
										<Typography variant="value1">
											{addCurrency(row.allowablePlanPay)}
										</Typography>
									</TableCell>
								</TableRow>
							))}
							<TableRow sx={{ height: { sm: 56, lg: 70 } }}>
								<TableCell sx={{ width: { sm: 136, lg: 230 } }}>
									<Typography variant="bodyLink" sx={{ pl: '10px' }}>
										{tClientLabels('newClaim.priceEstimate.totals')}
									</Typography>
								</TableCell>
								{showGross ? (
									<>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{addCurrency(totals.grossRetail)}
											</Typography>
										</TableCell>
										<TableCell align="center" $smallIcon></TableCell>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{addCurrency(totals.inStorePromotion)}
											</Typography>
										</TableCell>
										<TableCell align="center" $smallIcon></TableCell>
									</>
								) : null}
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.retailAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon></TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.contractualAdjustmentAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon>
									<Typography variant="bodyLink" style={{ color: palette.grey2.main }}>
										{addCurrency(totals.discountedAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.memberPayAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon></TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.allowablePlanPay)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Stack>
				{isError && (
					<Typography variant="bodyLink" color={palette.error.main}>
						{tClientLabels('newClaim.priceEstimate.grossRetailError')}
					</Typography>
				)}
			</BoxOverflow>
			{['beforeCalculation', 'onEdit'].includes(status) ? (
				<Stack direction={'row'} gap="20px">
					{['onEdit'].includes(status) ? (
						<Button
							variant="outlined"
							onClick={() => {
								setStatus('afterCalculation');
								setTempAmounts(amount);
							}}
						>
							{tClientLabels('commons.buttons.cancel')}
						</Button>
					) : null}
					<Button
						disabled={
							Boolean(
								tempAmounts.components.find(
									el => !el.retailAmount || (!el.grossRetail && showGross),
								),
							) || isError
						}
						variant="contained"
						onClick={handleCalculate}
					>
						{tClientLabels('newClaim.priceEstimate.calculate')}
					</Button>
				</Stack>
			) : null}
		</Stack>
	);
};

type TableCellProps = typeof TableCellUnstyled & {
	$smallIcon?: boolean;
};

const TableCell = styled(TableCellUnstyled)<TableCellProps>`
	&.MuiTableCell-head {
		padding: ${({ $smallIcon }) =>
			$smallIcon ? '8px 0px 8px 0px' : '8px 20px 8px 20px'};
		width: ${({ $smallIcon }) => ($smallIcon ? '24px' : 'auto')};
	}
	padding: ${({ $smallIcon }) =>
		$smallIcon ? '8px 0px 8px 0px' : '8px 20px 8px 20px'};
	@media (min-width: ${breakpoints.values.sm}px) {
		&.MuiTableCell-head {
			padding: ${({ $smallIcon }) =>
				$smallIcon ? '8px 0px 8px 0px' : '8px 10px 8px 10px'};
			width: ${({ $smallIcon }) => ($smallIcon ? '24px' : 'auto')};
		}
		padding: ${({ $smallIcon }) =>
			$smallIcon ? '8px 0px 8px 0px' : '8px 10px 8px 10px'};
	}
`;

const OutlinedInput = styled(InputNumber)`
	width: 91px;
	height: 28px;
	border-radius: 4px;
	padding-right: 8px;
	input {
		text-align: right;
		margin-right: 0px;
		margin-bottom: 16px;
		padding-right: 0px;
		padding-left: 8px;
		font-weight: 400;
		font-size: 13px;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='text'] {
		appearance: none;
		-moz-appearance: textfield;
	}
`;

const serviceIdToLable = (serviceId: number) =>
	`newClaim.priceEstimate.${
		[undefined, 'lenses', 'frame', 'exam', undefined, 'contacts'][serviceId]
	}`;

export default PriceEstimate;
