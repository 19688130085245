import { setSortingValues } from 'feature/form/formSlice';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Box, Stack, Typography } from 'styles';
import TextField from 'styles/textfield/TextFieldLogin';
import { ReactComponent as ChevronDownIcon } from 'assett/icons/arrow_down_icon.svg';
import { ReactComponent as ChevronUpIcon } from 'assett/icons/arrow_up_icon.svg';
import { handleChange } from 'feature/form/formSlice';
import { camelcase } from 'utils/utils';
import { IFilterCheckEligibility, sortingDirections } from 'types/Form';
import { changePage } from 'feature/claims/claimsSlice';
import { useTranslate } from '@tolgee/react';
import { TRANSLATION_KEYS } from 'utils/constants';

interface Props {
	name: string;
	children?: React.ReactNode;
	widthBox: number;
	widthInput?: number;
	primarySorting?: string;
}

const HeaderCellWithSorting: FC<Props> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<any>();
	const inputForm = useSelector((store: RootState) => store.form);
	const currentInputName = `input${camelcase(props.name)}`;

	const handleSorting = (name: string, value: string) => {
		dispatch(changePage(1)); // set the

		if (props.primarySorting) {
			dispatch(
				setSortingValues({
					name: name + `,${props.primarySorting}`,
					value: value + ',DESC',
				}),
			);
		} else {
			dispatch(setSortingValues({ name, value }));
		}
		// dispatch(setSortingValues({ name, value }));
	};

	const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(
			handleChange<any>({ name: evt.target.name, value: evt.target.value }),
		);
	};

	return (
		<Box sx={{ width: props.widthBox }}>
			{inputForm.search ? (
				!props.children ? (
					<TextField
						label={tClientLabels(`forms.header.${props.name}`)}
						sx={{ width: props.widthInput }}
						name={`input${camelcase(props.name)}`}
						onChange={handleOnChange}
						value={inputForm[currentInputName as keyof IFilterCheckEligibility] || ''}
					/>
				) : (
					props.children
				)
			) : (
				<Stack direction={'row'} alignItems={'center'}>
					<Typography
						variant="bodyLink"
						sx={{ ml: 1.7, cursor: 'pointer' }}
						onClick={() =>
							handleSorting(
								props.name,
								inputForm.sortingFields.split(',')[0] === props.name &&
									inputForm.sortingDirections.split(',')[0] === sortingDirections.ASC
									? sortingDirections.DESC
									: sortingDirections.ASC,
							)
						}
					>
						{tClientLabels(`forms.header.${props.name}`)}
					</Typography>

					{inputForm.sortingFields.split(',')[0] === props.name ? (
						inputForm.sortingDirections.split(',')[0] === sortingDirections.ASC ? (
							<ChevronUpIcon />
						) : (
							<ChevronDownIcon />
						)
					) : null}
				</Stack>
			)}
		</Box>
	);
};

export default HeaderCellWithSorting;
